import Icon from '__designkit__/icon/Icon';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { V2TextColors, V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import Fonts from '__designkit__/common/fonts';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { sub } from 'date-fns';
import ImgNotice1 from './imgNotice1.png';
import ImgNotice2 from './imgNotice2.png';
import ImgNotice3 from './imgNotice3.png';
import ImgNotice4 from './imgNotice4.png';
import ImgNotice5 from './imgNotice5.png';
import ImgNotice6 from './imgNotice6.png';
import ImgNotice7 from './imgNotice7.png';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 128px;

  .order {
    display: flex;
    gap: 8px;
    margin-bottom: ${SpaceValue.XL}px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: ${V2BackgroundColors.disabled};
      color: ${V2TextColors.disabled};

      &.order-on {
        background-color: ${V2BackgroundColors.accent_green_default};
        color: ${V2TextColors.inverse};
      }
    }
  }

  .guide-text {
    white-space: break-spaces;
  }

  .notice-img {
    width: fit-content;
    height: auto;
    object-fit: contain;
  }
`;

const NoticeFrame = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    padding-bottom: ${SpaceValue.XXXL}px;
    border-bottom: 1px solid ${V2BorderColors.default};

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    &:not(:first-child) {
      padding-top: ${SpaceValue.XXXL}px;
    }
  }
`;

const ChecklistContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: ${SpaceValue.XL}px;
`;

const ChecklistItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border: 1px solid #E9EAEB;
  border-radius: 8px;
`;

const QuestionText = styled.span`
  font: ${Fonts.B2_Medium};
  color: #333436;
  white-space: break-spaces;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  width: 20px;
  height: 20px;
  border-radius: 2px;
  appearance: none;
  background-color: #FFFFFF;
  border: 1px solid #DFE0E2;
  cursor: pointer;
  transition: all 0.2s;

  &:checked {
    background-color: #02C551;
    border-color: #02C551;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &:checked::after {
    content: '✔';
    color: white;
    font-size: 14px;
    font-weight: bold;
    position: absolute;
  }
`;

interface VideoRecordNoticeProps {
  activeStep?: number | null;
  title: string;
  subTitle: string;
  guideText: string;
  notice?: string;
  img: string;
}

const VideoRecordNotice = ({ activeStep, title, subTitle, guideText, img, notice }: VideoRecordNoticeProps) => {
  return (
    <Frame>
      {activeStep ? (
        <div className='order'>
          {[1, 2, 3].map((step) => (
            <div key={step} className={step === activeStep ? 'order-on' : ''}>
              {step}
            </div>
          ))}
        </div>
      ) : <></>}
      <V2Text
        fontStyle={V2FontStyleType.body_3_sb}
        color={V2TextColorsType.brand}
      >
        {title}
      </V2Text>
      <SpacingBlock size={SpaceValue.XXXS} vertical />
      <V2Text
        fontStyle={V2FontStyleType.title_1_b}
        color={V2TextColorsType.default}
      >
        {subTitle}
      </V2Text>
      <SpacingBlock size={SpaceValue.M} vertical />
      <V2Text
        fontStyle={V2FontStyleType.body_1_m}
        color={V2TextColorsType.danger}
      >
        {notice}
      </V2Text>
      <SpacingBlock size={SpaceValue.M} vertical />
      <V2Text
        fontStyle={V2FontStyleType.body_1_m}
        color={V2TextColorsType.subtle}
        className='guide-text'
      >
        {guideText}
      </V2Text>
      <SpacingBlock size={SpaceValue.XL} vertical />
      <img className='notice-img' src={img} alt='영상 가이드 이미지' />
    </Frame>
  );
};

const VideoRecordNotice1 = () => (
  <NoticeFrame>
    <VideoRecordNotice
      activeStep={1}
      title='촬영 준비단계'
      subTitle='용모 가이드'
      notice='용모 가이드에 맞추지 않을 경우, 불합격 처리 됩니다.'
      guideText={`첫인상을 위해 깔끔하고 단정한 용모를 점검해 주세요.\n자연스러운 메이크업으로 깔끔함을 더해주세요.`}
      img={ImgNotice1}
    />
    <VideoRecordNotice
      title='촬영 준비단계'
      subTitle='복장 가이드'
      notice='복장 가이드에 맞추지 않을 경우, 불합격 처리 됩니다.'
      guideText={`깔끔하고 단정한 복장을 점검해 주세요.\n복장은 면접 태도에 중요한 요소 중 하나입니다.`}
      img={ImgNotice2}
    />
  </NoticeFrame>
);

const VideoRecordNotice2 = () => (
  <NoticeFrame>
    <VideoRecordNotice
      activeStep={2}
      title='촬영 준비단계'
      subTitle='촬영 준비물'
      notice='삼각대를 활용하지 않을 경우, 불합격 처리 됩니다.'
      guideText={`준비물을 체크해 주세요.\n준비물이 없으실 경우, 촬영이 불가합니다.`}
      img={ImgNotice3}
    />
    <VideoRecordNotice
      title='촬영 준비단계'
      subTitle='촬영 장소 선정'
      notice='배경이 깔끔하지 않을 경우, 불합격 처리 됩니다.'
      guideText={`깔끔하고 단정한 복장을 점검해 주세요.\n복장은 면접 태도에 중요한 요소 중 하나입니다.`}
      img={ImgNotice4}
    />
    <VideoRecordNotice
      title='촬영 준비단계'
      subTitle='조명 위치 및 밝기'
      notice='조명이 어두울  경우, 불합격 처리 됩니다.'
      guideText={`스튜디오 조명을 활용하지 않아도 충분합니다.\n음영이 최소화 되는 위치에 카메라를 설치해 주세요.`}
      img={ImgNotice5}
    />
  </NoticeFrame>
);

const VideoRecordNotice3 = () => (
  <NoticeFrame>
    <VideoRecordNotice
      activeStep={3}
      title='촬영 준비단계'
      subTitle='스크립트 가이드'
      notice='스크립트 암기가 안되었을 경우, 불합격 처리 됩니다.'
      guideText={`1. 밝은 모습과 자신감 있는 목소리로 촬영해 주세요.\n2. 스크립트를 보면서 읽을 경우 시선이 부자연스러울 수 있습니다. 자기소개 내용을 암기해 주세요.`}
      img={ImgNotice6}
    />
    <VideoRecordNotice
      title='촬영 준비단계'
      subTitle='답변 태도 가이드'
      guideText={`답변 태도는 합격/불합격의 가장 중요한 요소입니다.\n아래 답변 태도를 꼭 숙지하고 촬영에 임해주세요.`}
      img={ImgNotice7}
    />
  </NoticeFrame>
);

const VideoRecordNotice4: React.FC<{ onChecklistChange: (isAllChecked: boolean) => void }> = ({ onChecklistChange }) => {
  const questions = [
    '깔끔한 용모와 복장을 갖추었나요?',
    `촬영 준비물을 모두 보유하고 있나요?\n(스마트폰 , 삼각대)`,
    `깔끔한 배경 앞에 위치해 있나요?\n(단색 벽지 , 커튼)`,
    '스크립트를 암기 하였나요?',
    '충분한 연습을 하였나요?',
  ];

  const [checkedItems, setCheckedItems] = useState<boolean[]>(Array(questions.length).fill(false));

  const handleCheckboxChange = (index: number) => {
    const updatedCheckedItems = [...checkedItems];
    updatedCheckedItems[index] = !updatedCheckedItems[index];
    setCheckedItems(updatedCheckedItems);

    onChecklistChange(updatedCheckedItems.every(Boolean));
  };

  return (
    <Frame>
      <V2Text
        fontStyle={V2FontStyleType.body_3_sb}
        color={V2TextColorsType.brand}
      >
        영상 가이드
      </V2Text>
      <SpacingBlock size={SpaceValue.XXXS} vertical />
      <V2Text
        fontStyle={V2FontStyleType.title_1_b}
        color={V2TextColorsType.default}
      >
        자기점검 체크리스트
      </V2Text>
      <SpacingBlock size={SpaceValue.M} vertical />
      <V2Text
        fontStyle={V2FontStyleType.body_1_m}
        color={V2TextColorsType.danger}
      >
        아래 항목은 불합격 처리가 될 수 있는 항목입니다.
      </V2Text>
      <V2Text
        fontStyle={V2FontStyleType.body_1_m}
        color={V2TextColorsType.subtle}
      >
        점검 체크 리스트를 확인하고, 촬영을 시작해 주세요.
      </V2Text>
      <ChecklistContainer>
        {questions.map((question, index) => (
          <ChecklistItem key={question}>
            <QuestionText>{question}</QuestionText>
            <Checkbox
              checked={checkedItems[index]}
              onChange={() => handleCheckboxChange(index)}
            />
          </ChecklistItem>
        ))}
      </ChecklistContainer>
    </Frame>
  );
};

export { VideoRecordNotice1, VideoRecordNotice2, VideoRecordNotice3, VideoRecordNotice4 };
