import styled from 'styled-components';
import ImgBgIntro from 'assets/_v2/profile/bg_intro.png';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import SpacingBlock from '__designkit__/components/SpacingBlock';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import V2Icon from '__pc__/components/common/v2Design/icon';
import { V2BorderColors, V2BorderColorsType } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { IconLogo } from 'consts/assets/icons/iconPages';
import { Link } from 'react-router-dom';
import RoutePaths from 'consts/RoutePaths';
import { useEffect, useState } from 'react';
import Login from 'models/Login';
import request from 'utils/request';
import URIs from 'consts/URIs';
import { isBeforeDateOnly } from 'utils/DateUtils';
import ImgGuide from './imgGuide.png';

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  z-index: 1;
`;

const Frame = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: ${SpaceValue.XXL}px 0;
  
  &::before {
    content: '';
    position: absolute;
    display: flex;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    opacity: 0.28;
    width: 100%;
    height: 400px;
    background: #02C551;
    filter: blur(120px);
    z-index: -1;
  }

  img {
    margin-bottom: ${SpaceValue.XL}px;
    width: fit-content;
    height: 200px;
  }

  /* 버튼 리스트 */
  .btn-list {
    display: flex;
    flex-direction: column;
    gap: ${SpaceValue.XS}px;
    margin: ${SpaceValue.XXXL}px 0 ${SpaceValue.XL}px 0;
    width: 100%;
    padding: 0 ${SpaceValue.L}px;

    .apply-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: ${SpaceValue.XL}px;
      border: 1px solid ${V2BorderColors.default};
      border-radius: ${SpaceValue.M}px;

      .badge {
        display: flex;
        align-items: center;
        gap: ${SpaceValue.XXXS}px;
      }
    }
  }
`;

const ContentsFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
export interface IGetExistFilesRs{
  hasMatchingSelfIntroductionVideo:boolean;
  hasMatchingMotivationVideo:boolean;
  hasMatchingProfilePhoto:boolean;
  hasMatchingSelfExpressionPhoto:boolean;
}
const MatchingGuideMainPage = () => {
  const [isIntroductionComplete, setIsIntroductionComplete] = useState(false);
  const [isVideoComplete, setIsVideoComplete] = useState(false);
  useEffect(() => {
    const init = async () => {
      try {
        const response = await request<any>({
          method: 'get',
          url: URIs.get_v1_match_applicants_introduction,
        });

        if (response.title) {
          setIsIntroductionComplete(true);
        }
      } catch (e) {
        console.error(e);
      }
      try {
        const response = await request<IGetExistFilesRs>({
          method: 'get',
          url: URIs.get_v1_match_applicants_files_exist,
        });
        if (response.hasMatchingSelfIntroductionVideo && response.hasMatchingMotivationVideo && response.hasMatchingProfilePhoto && response.hasMatchingSelfExpressionPhoto) {
          setIsVideoComplete(true);
        }
      } catch (e) {
        console.error(e);
      }
    };
    init();
  }, []);
  return (
    <>
      <Header>
        <IconLogo className='logo' />
      </Header>
      <Frame>
        <ContentsFrame>
          <img src={ImgGuide} alt='지원 가이드' />
          {/* <V2Text fontStyle={V2FontStyleType.heading_3_b} color={V2TextColorsType.default}>2025년 상반기<br />마이다스 수시 채용</V2Text> */}
          <V2Text fontStyle={V2FontStyleType.heading_3_b} color={V2TextColorsType.default}>JOBDA 모바일 테스트</V2Text>
          <SpacingBlock size={SpaceValue.XL} vertical />
          {/* <V2Text className='intro-desc' fontStyle={V2FontStyleType.body_1_m} color={V2TextColorsType.default}>
            아래 항목을 준비하여 마감일까지
            <br />
            업로드해 주시기 바랍니다. 제출된 자료는
            <br />
            마이다스 그룹 리더들이 검토하며
            <br />
            면접 대상자 선정을 위한 중요한 자료로 사용됩니다.
          </V2Text> */}
          <V2Text className='intro-desc' fontStyle={V2FontStyleType.body_1_m} color={V2TextColorsType.default}>
            온라인 면접 환경을 제공하기 위한<br />
            JOBDA 앱 테스트 를 진행합니다.<br />
            제출된 자료는 가이드 준수 여부에 따라<br />
            최대 200,000원의 알바비용이 지급됩니다.
          </V2Text>
          <SpacingBlock size={SpaceValue.XL} vertical />
          <V2Text className='intro-desc' fontStyle={V2FontStyleType.body_1_m} color={V2TextColorsType.danger}>
            (★ 중요) 취업을 희망하는 기업에<br />
            온라인 과제를 제출하는 상황임을 고려하여<br />
            제출기한에 맞춰 업로드해 주시기 바랍니다.
          </V2Text>
        </ContentsFrame>

        <div className='btn-list'>
          {
            !isIntroductionComplete ? (
              <Link to={RoutePaths.matchingGuideIntoduce}>
                <div className='apply-title'>
                  <V2Text
                    element={V2TextOption.element.span}
                    fontStyle={V2TextOption.fontStyle.body_1_b}
                    color={V2TextOption.color.default}
                  >
                    자기소개 문구
                  </V2Text>
                  <div className='badge'>
                    <V2Icon
                      name={V2IconOption.name.errorFilled}
                      size={V2IconOption.size.S}
                      fill={V2IconOption.fill.accent_red}
                    />
                    <V2Text
                      element={V2TextOption.element.span}
                      fontStyle={V2TextOption.fontStyle.body_2_sb}
                      color={V2TextOption.color.danger}
                    >
                      미완료
                    </V2Text>
                  </div>
                </div>
              </Link>
            ) : (
              <div>
                <div className='apply-title'>
                  <V2Text
                    element={V2TextOption.element.span}
                    fontStyle={V2TextOption.fontStyle.body_1_b}
                    color={V2TextOption.color.disabled}
                  >
                    자기소개 문구
                  </V2Text>
                  <div className='badge'>
                    <V2Icon
                      name={V2IconOption.name.checkCircleFilled}
                      size={V2IconOption.size.S}
                      fill={V2IconOption.fill.primary}
                    />
                    <V2Text
                      element={V2TextOption.element.span}
                      fontStyle={V2TextOption.fontStyle.body_2_sb}
                      color={V2TextOption.color.success}
                    >
                      완료
                    </V2Text>
                  </div>
                </div>
              </div>
            )
          }
          {
            !isVideoComplete ? (
              <Link to={RoutePaths.matchingGuideVideo}>
                <div className='apply-title'>
                  <V2Text
                    element={V2TextOption.element.span}
                    fontStyle={V2TextOption.fontStyle.body_1_b}
                    color={V2TextOption.color.default}
                  >
                    프로필 영상 및 사진
                  </V2Text>
                  <div className='badge'>
                    <V2Icon
                      name={V2IconOption.name.errorFilled}
                      size={V2IconOption.size.S}
                      fill={V2IconOption.fill.accent_red}
                    />
                    <V2Text
                      element={V2TextOption.element.span}
                      fontStyle={V2TextOption.fontStyle.body_2_sb}
                      color={V2TextOption.color.danger}
                    >
                      미완료
                    </V2Text>
                  </div>
                </div>
              </Link>
            ) : (
              <div>
                <div className='apply-title'>
                  <V2Text
                    element={V2TextOption.element.span}
                    fontStyle={V2TextOption.fontStyle.body_1_b}
                    color={V2TextOption.color.disabled}
                  >
                    프로필 영상 및 사진
                  </V2Text>
                  <div className='badge'>
                    <V2Icon
                      name={V2IconOption.name.checkCircleFilled}
                      size={V2IconOption.size.S}
                      fill={V2IconOption.fill.primary}
                    />
                    <V2Text
                      element={V2TextOption.element.span}
                      fontStyle={V2TextOption.fontStyle.body_2_sb}
                      color={V2TextOption.color.success}
                    >
                      완료
                    </V2Text>
                  </div>
                </div>
              </div>
            )
          }
          {/* 14일에 활성화 */}
          {/* <div className='apply-title'>
            <V2Text
              element={V2TextOption.element.span}
              fontStyle={V2TextOption.fontStyle.body_1_b}
              color={V2TextOption.color.disabled}
            >
              역량검사
            </V2Text>
            <div className='badge'>
              <V2Icon
                name={V2IconOption.name.checkCircleFilled}
                size={V2IconOption.size.S}
                fill={V2IconOption.fill.primary}
              />
              <V2Text
                element={V2TextOption.element.span}
                fontStyle={V2TextOption.fontStyle.body_2_sb}
                color={V2TextOption.color.success}
              >
                완료
              </V2Text>
            </div>
          </div>
          <V2Text
            element={V2TextOption.element.span}
            fontStyle={V2TextOption.fontStyle.body_3_m}
            color={V2TextOption.color.subtlest}
          >
            응시는 PC 환경에서 가능합니다.
          </V2Text> */}
        </div>

      </Frame>
    </>
  );
};

export default MatchingGuideMainPage;
